import React from 'react';
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '易思网络是一家专注于软件研发的科技公司',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: (
          <span>
            <span>
              <p>
                定制软件研发、机器视觉服务、体育场馆 SaaS、青少儿体育培训<br />
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>易思网络提供专业的服务</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>业务</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                业务范围涵盖小程序开发、企业微信开发、体育场馆软件、教务软件以及机器视觉智能高光集锦录制等领域<br />
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>服务</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                我们致力于为客户提供高效、智能的解决方案，帮助他们实现数字化转型和业务升级。我们的专业技术团队具有多年软件开发和项目管理经验，能够为客户提供一流的技术支持和服务<br />
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>特点</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                在机器视觉智能高光集锦录制方面，我们拥有先进的技术和经验丰富的团队，能够为客户提供高质量、高效率的比赛精彩时刻集锦录制服务。我们的机器视觉智能算法能够自动识别比赛中的亮点瞬间，并实时剪辑成集锦，从而大大提高了集锦录制的效率和质量。无论是体育赛事还是企业活动，我们都能为客户提供最佳的集锦录制方案。<br />
              </p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        杭州易思网络有限公司 &nbsp;< a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">浙ICP备2022020806号-1</ a><br />
      </span>
    ),
  },
};
